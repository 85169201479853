import React, { ChangeEvent, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import axios from 'axios';
import "./AccountLedger.css"
import { formatDate, formatedTime } from '../../../../utils/utility';
import Loader from '../../../../Components/Loader/Loader';
import { ToastContainer, toast } from "react-toastify";
import { User_Context } from '../../../../Contexts/User';



interface Item {
    _id: string;
    balance: number;
    amount: number;
    lastBalance: number;
    userId: string;
    name: string;
    transactionId: string;
    marketName: string;
    settlementId: string;
    providerName: string;
    transactionType: string;
    action: string;
    description: Record<string, any>;
    createdAt: string;
    createdOn: string;
    updatedOn: string;
    remark: string;
    closingBalance: number;
    transactor: string;
}

interface Description {
    eventName: string,
    givenBy: string
}

interface PartyData {
    closingBalance: number;
    items: Item[];
    totalPages: number;
    total: number;
    description: Description
}

const AccountLedger = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [partyName, setPartyName] = useState("")
    const [senderPartyName, setSenderPartyName] = useState("")
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchResults2, setSearchResults2] = useState<any[]>([]);
    const [selectedParty, setSelectedParty] = useState<string | null>(null);
    const [selectedParty2, setSelectedParty2] = useState<string | null>(null);
    const [isSelecting, setIsSelecting] = useState(false);
    const [isSelecting2, setIsSelecting2] = useState(false);
    const [senderPartyRemark, setSenderPartyRemark] = useState("")
    const [senderPartyAmount, setSenderPartyAmount] = useState<number>(0)
    const [transType, setTransType] = useState<string>("")
    const [transferType, setTransferType] = useState<string>("")
    const [partyData, setPartyData] = useState<PartyData>({
        closingBalance: 0,
        items: [],
        description: {
            eventName: "",
            givenBy: ""
        },
        totalPages: 0,
        total: 0
    });
    const { User } = useContext(User_Context)
    const searchParty = () => {
        if (!partyName) {
            toast.error("Please enter party name")
        } else {
            setLoading(true);
            const token = localStorage.getItem("token");
            let payload = {
                name: transferType === "Wallet" ? "wallet_660d4e9c485f4a2b1fb3f4a2" : partyName,
                itemsPerPage: 10,
                pageNo: 1,
                creatorId: User.data._id
            }
            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/userClosingBalance`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(payload) },
            };
            axios
                .request(config)
                .then((response) => {
                    const decryptedData = decryptData(response.data.data);
                    setPartyData(decryptedData.payload);
                    setLoading(false)
                    setSenderPartyName("")
                    setSenderPartyAmount(0)
                    setSenderPartyRemark("")
                    setTransType("")
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                    setLoading(false);
                });
        }
    };

    const walletTrans = () => {
        if (!partyName) {
            toast.error("Please enter Party Name")
        } else if (!senderPartyName) {
            toast.error("Please enter sender party name")
        } else if (!senderPartyAmount) {
            toast.error("Please enter sender party amount")
        } else if (!senderPartyRemark) {
            toast.error("Please enter sender party remark")
        } else if (!transType) {
            toast.error("Please enter transaction type")
        }
        else {
            setLoading(true);
            const token = localStorage.getItem("token");
            let payload = {
                mainWallet: "wallet_660d4e9c485f4a2b1fb3f4a2",
                toParty: senderPartyName,
                amount: senderPartyAmount,
                remark: senderPartyRemark,
                creatorId: User.data._id,
                transactionType: transType
            }
            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/walletTransfer`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(payload) },
            };
            axios
                .request(config)
                .then((response) => {
                    searchParty()
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                    setLoading(false);
                });
        }
    };
    const senderParty = () => {
        if (!partyName) {
            toast.error("Please enter Party Name")
        } else if (!senderPartyName) {
            toast.error("Please enter sender party name")
        } else if (!senderPartyAmount) {
            toast.error("Please enter sender party amount")
        } else if (!senderPartyRemark) {
            toast.error("Please enter sender party remark")
        } else if (!transType) {
            toast.error("Please enter transaction type")
        }
        else {
            setLoading(true);
            const token = localStorage.getItem("token");
            let payload = {
                fromParty: partyName,
                toParty: senderPartyName,
                amount: senderPartyAmount,
                remark: senderPartyRemark,
                creatorId: User.data._id,
                transactionType: transType
            }
            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/partyTransfer`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(payload) },
            };
            axios
                .request(config)
                .then((response) => {
                    searchParty()
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                    setLoading(false);
                });
        }
    };

    const handleChangePartyName = (e: ChangeEvent<HTMLInputElement>) => {
        setPartyName(e.target.value)
        setIsSelecting(false);
    }

    const handleSenderPartyName = (e: ChangeEvent<HTMLInputElement>) => {
        setSenderPartyName(e.target.value)
        setIsSelecting2(false);
    }

    const handleSelectParty = (partyName: string) => {
        setSenderPartyName(partyName);
        setSelectedParty(partyName);
        setSearchResults([]);
        setIsSelecting2(true);
    };
    const handleSelectParty2 = (partyName: string) => {
        setPartyName(partyName);
        setSelectedParty2(partyName);
        setSearchResults2([]);
        setIsSelecting(true);
    };

    const handleSenderPartyAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const inputAmount = Number(e.target.value);
        setSenderPartyAmount(inputAmount)
    }

    const handleSenderPartyRemark = (e: ChangeEvent<HTMLInputElement>) => {
        setSenderPartyRemark(e.target.value)
    }

    const fetchData = async (searchUserName?: string) => {
        try {
            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/playerSearch`,
                headers:
                {
                    Authorization: `Bearer ${User.token}`,
                },
                data: {
                    token: encryptData({
                        name: searchUserName,
                        creatorId: User.data._id
                    })
                }
            };
            let response = await axios.request(config)
            let deResponse = decryptData(response.data.data)
            setSearchResults(deResponse?.payload || []);
        } catch (error: any) {
            console.log(error.message)
        }
    };
    const fetchData2 = async (searchUserName?: string) => {
        try {
            let config = {
                method: "post",
                url: `${API_Endpoint}/masterFlow/playerSearch`,
                headers:
                {
                    Authorization: `Bearer ${User.token}`,
                },
                data: {
                    token: encryptData({
                        name: searchUserName,
                        creatorId: User.data._id
                    })
                }
            };
            let response = await axios.request(config)
            let deResponse = decryptData(response.data.data)
            setSearchResults2(deResponse?.payload || []);
        } catch (error: any) {
            console.log(error.message)
        }
    };


    useEffect(() => {
        if (!isSelecting) {
            const timerId = setTimeout(() => {
                if (partyName) {
                    fetchData2(partyName);
                } else {
                    fetchData2();
                }
            }, 500);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [partyName, isSelecting]);

    useEffect(() => {
        if (!isSelecting2) {
            const timerId = setTimeout(() => {
                if (senderPartyName) {
                    fetchData(senderPartyName)
                }
                else {
                    fetchData()
                }
            }, 500);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [senderPartyName, isSelecting2]);

    const transactionTypes = ['CR', 'DR'];
    const transferTypes = ['Client', 'Wallet'];

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTransType(event.target.value);
    };

    const handleTransferChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(event.target.value === "Wallet"){
            setPartyName("Main Wallet")
            setTransferType(event.target.value);
        } else if (event.target.value === "Client") {
            setPartyName("")
            setTransferType(event.target.value);
        }
    };

    const handleSelectApi = () => {
        if (transferType === "Client") {
            senderParty()
        } else {
            walletTrans()
        }
    };

    return (
        <>
            {loading ? <Loader /> :
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                            <div style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => navigate("/master-flow")}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                                <span className="user-name"> Back</span>
                            </div>
                            <div className="py-1 px-2">
                                <nav aria-label="breadcrumb" className="breadcrumbs">
                                    <ol className="breadcrumb bg-transparent mb-0 px-0">
                                        <li className="breadcrumb-item">
                                            <span className="account-ledger">Account Ledger Details</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="px-1 py-1 mb-2 outer-div">
                                <div className="inner-div">
                                    <label>Transfer Type</label>
                                    <select className='select-tns-type' value={transferType} onChange={handleTransferChange}>
                                        <option value="">Select Transfer Type</option>
                                        {transferTypes.map((type, index) => (
                                            <option key={index} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="inner-div">
                                    <label>Party Name</label>
                                    <input onChange={handleChangePartyName} value={partyName} className='input-party-name' type='text' />
                                    {searchResults2.length > 0 && (
                                        <ul className='search-results2'>
                                            {searchResults2.map((result) => (
                                                <li
                                                    key={result._id}
                                                    onClick={() => handleSelectParty2(result.name)}
                                                    style={{ cursor: 'pointer', padding: '5px' }}
                                                >
                                                    {result.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="inner-div">
                                    <span className='mt-1'></span>
                                    <button onClick={searchParty} className='search-party'>Search</button>
                                </div>
                                <div className="inner-div">
                                    <label>Closing Balance</label>
                                    <div className="inner-div">
                                        <span className='closing-balance'>{partyData?.closingBalance.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-1 py-2">
                                <div className="table-responsive">
                                    <table className="table table-view table-wallet">
                                        <thead>
                                            <tr>
                                                <th>Transactor</th>
                                                <th>Remarks</th>
                                                <th>Tns Type</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                                <th>Balance</th>
                                                <th>Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partyData.items.map((item: Item) => (
                                                <tr key={item._id}>
                                                    <td>{item?.transactor}</td>
                                                    <td>{item?.remark}</td>
                                                    <td>{item?.transactionType}</td>
                                                    <td>{item?.transactionType === "CR" ? item?.amount.toFixed(2) : 0}</td>
                                                    <td>{item?.transactionType === "DR" ? item?.amount.toFixed(2) : 0}</td>
                                                    <td>{item?.closingBalance?.toFixed(2)}</td>
                                                    <td>{`${formatDate(item?.createdAt)} ${formatedTime(item?.createdAt)}`}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='px-1 py-1 mb-2 outer-div'>
                                <div className="inner-div">
                                    <span>Party Name</span>
                                    <input onChange={handleSenderPartyName} value={senderPartyName} className='' type='text' />
                                    {searchResults.length > 0 && (
                                        <ul className='search-results'>
                                            {searchResults.map((result) => (
                                                <li
                                                    key={result._id}
                                                    onClick={() => handleSelectParty(result.name)}
                                                    style={{ cursor: 'pointer', padding: '5px' }}
                                                >
                                                    {result.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="inner-div">
                                    <span>Amount</span>
                                    <input onChange={handleSenderPartyAmount} value={senderPartyAmount === 0 ? '' : senderPartyAmount} className='' type='number' />
                                </div>
                                <div className="inner-div">
                                    <span>Remarks</span>
                                    <input onChange={handleSenderPartyRemark} value={senderPartyRemark} className='' type='text' />
                                </div>
                                <div className="inner-div">
                                    <span>Tns Type</span>
                                    <select className='select-tns-type' value={transType} onChange={handleSelectChange}>
                                        <option value="">Select Transaction Type</option>
                                        {transactionTypes.map((type, index) => (
                                            <option key={index} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="inner-div">
                                    <span></span>
                                    <button onClick={handleSelectApi} className='search-party'>Ok</button>
                                </div>
                            </div>
                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row align-items-center justify-content-lg-between">
                                        <div className="col-lg-6 mb-lg-0  ">
                                            <div className="copyright text-center text-sm text-muted text-lg-start">
                                                © fairbets.co
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        About Us
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Blog
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link pe-0 text-muted"
                                                        target="_blank"
                                                    >
                                                        License
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </main>
                </div>}
        </>
    )
}

export default AccountLedger