import React from 'react';
import { useContext, useState, useEffect, ChangeEvent,FormEvent } from "react";
import { ToastContainer, toast } from "react-toastify";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Pagination, Stack } from "@mui/material";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import "./MasterFlow.css";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AdminTable from "./admin";
import SubAdminTable from "./subAdmin";
import SuperMasterTable from "./superMaster";
import MasterTable from "./master";
import PlayerTable from "./player";
import Button from "@mui/material/Button";
import Reusable_Input from '../../../../Components/InputField/InputField';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';

interface userMasterData {
    _id: string,
    mobile: string,
    pnl: number,
    fullpnl:number,
    exposer: number,
    initialBalance: number,
    currentBalance: number,
    potentialGain:number
    potentialLoss:number,
    transferPnl:number
}
export default function MasterFlow() {
    const [userMasterData, setUserMasterData] = useState<userMasterData>({} as userMasterData);
    const [searchUserId, setSearchUserId] = useState<string>("");
    const [searchUserName, setSearchUserName] = useState<string>("");
    const [searchUserMob, setSearchUserMob] = useState<string>("");
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const Navigate = useNavigate();
    const Location = useLocation()
    const { User } = useContext(User_Context);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [table, setTable] = React.useState(Location?.state?.table || 'player')
    const [openAddPopup, setAddOpenPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(parseInt(Location?.state?.page) || 1);
   
    const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserId(event.target.value);
    };
    const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserName(event.target.value);
    };
    const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserMob(event.target.value);
    };

    const filterUser = () => {
        setLoading(true);
        const filter: { _id?: string, name?: string, mobile?: string } = {};
        if (searchUserId) {
            filter._id = searchUserId
        }
        if (searchUserName) {
            filter.name = searchUserName
        }
        if (searchUserMob) {
            filter.mobile = searchUserMob
        }
        const token = localStorage.getItem("token");
        let data = {
            pageSize: 10,
            pageNumber: currentPage,
            filter: filter
        };
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/getProfitLoss`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { token: encryptData(data) },
        };

        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                const pages = response.data.data.payload.count / 10;
                setTotalPages(Math.ceil(pages));
                //setUserMasterData(response.data.data.payload.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }

    useEffect(() => {
        if(User.data.type == "superAdmin"){
            setTable(Location?.state?.table || "admin")
           } 
           else if(User.data.type == "admin"){
            setTable(Location?.state?.table || "subAdmin")
           }
           else if(User.data.type == "subAdmin"){
            setTable(Location?.state?.table || "superMaster")
           }
           else if(User.data.type == "superMaster"){
            setTable(Location?.state?.table || "master")
           }
           else if(User.data.type == "master"){
            setTable(Location?.state?.table || "player")
           } 
       getData()
    }, []);

    const getData =() =>{
        setLoading(true);
        const token = localStorage.getItem("token");
        let config = {
            method: "post",
            url: `${API_Endpoint}/masterFlow/userDetail`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                token: encryptData({
                    _id: User.data?._id,
                    role: User.data?.type
                }
                )
            },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setUserMasterData(response.data.data.payload);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }
    
    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setTable(event.target.value)
        setCurrentPage(1)
        Navigate('',{ state: {page:1,table:event.target.value}})
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem("token");
        let config = {
            method: "post",
            url: `${API_Endpoint}/masterFlow/resetPnl`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                token: encryptData({
                    _id: User.data?._id,
                    // role: User.data?.type
                }
                )
            },
        };
        axios
            .request(config)
            .then((response) => {
                if(response.data.success){
                    toast.success("PNL Reset successful");
                    setAddOpenPopup(false);
                    getData()
                    setLoading(false);
                }
                else {
                    toast.error("Reset failed!");
                    setAddOpenPopup(false);
                    setLoading(false);
                }      
            })
            .catch((error) => {
                console.log(error);
                setAddOpenPopup(false);
                toast.error("Something went wrong!");
                setLoading(false);
            });
    };

    const [currentPageChild, setCurrentPageChild] = useState(0);

    const updateParentData =(incoming?:any)=>{
        setCurrentPageChild(incoming)
        getData()
    }
    

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                                <div style={{display:'flex'}}>
                                    <Breadcrumbs tab={"Master Flow"}

                                        // button={User.data.type === 'superAdmin' && <Button
                                        //     onClick={() => setAddOpenPopup(true)}
                                        //     className="btn-payment addUser"
                                        //     variant="contained"
                                        // >
                                        //     Transfer
                                        // </Button>
                                        // }
                                         button={User.data.type === 'superAdmin' && <Button
                                            onClick={() => setAddOpenPopup(true)}
                                            className="btn-payment addUser"
                                            variant="contained"
                                        >
                                            Monday Final
                                        </Button>
                                        }
                                    />

                                    <FontAwesomeIcon

                                        className="fa fa-pencil-square icon-home icon-banner"
                                        icon={faRotateRight}
                                        style={{ cursor: "pointer",marginTop:"15px",marginRight:"15px" }}
                                        onClick={() => getData()}
                                    />

                                </div>
                            <div>
                                <Dialog open={openAddPopup} onClose={() => setAddOpenPopup(false)}>
                                    <DialogContent>
                                        <div>
                                        <h6 style={{display:'flex',justifyContent:"center"}}>Are You Sure ?</h6>
                                        <p className='mb-1'> It will reset PNL to Zero</p>
                                        </div>
                                        <form className='masterform' onSubmit={handleSubmit}>
                                            <DialogActions>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    onClick={() => setAddOpenPopup(false)}
                                                    color="primary"
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Reset
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <div>
                                <div className="container-fluid mt-2">
                                    <div className="row masterFlow">
                                        <div style={{ borderRight: "1px solid #fff" }} className="col-6 col-xl-3 col-sm-6 pdrt">
                                            <select value={table} className="custom-select" onChange={handleChange as any}>

                                                {User.data.type === 'superAdmin' && (
                                                    <>
                                                        <option value="admin" selected>Admin list</option>
                                                        <option value="subAdmin" >Sub Admin List</option>
                                                        <option value="superMaster">Super Master List</option>
                                                        <option value="master">Master list</option>
                                                        <option value="player">User List</option>
                                                    </>
                                                )}
                                                {User.data.type === 'admin' && (
                                                    <>
                                                        <option value="subAdmin" selected>Sub Admin List</option>
                                                        <option value="superMaster">Super Master List</option>
                                                        <option value="master">Master list</option>
                                                        <option value="player">User List</option>
                                                    </>
                                                )}
                                                {User.data.type === 'subAdmin' && (
                                                    <>
                                                        <option value="superMaster" selected>Super Master List</option>
                                                        <option value="master">Master list</option>
                                                        <option value="player">User List</option>
                                                    </>
                                                )}
                                                {User.data.type === 'superMaster' && (
                                                    <>
                                                        <option value="master" selected>Master list</option>
                                                        <option value="player">User List</option>

                                                    </>
                                                )}

                                                {User.data.type === 'master' && (
                                                    <>
                                                        <option value="player" selected>User List</option>
                                                    </>
                                                )}

                                            </select>
                                        </div>
                                        <div className="col-6 col-xl-3 col-sm-6 pdrt masterLabel ">
                                            <p
                                             onClick={() => {
                                                const url = `/user-report-master/${User.data._id}/${User.data.name}/${currentPage}/${table}`;
											    Navigate(url);
                                            }} style={{ cursor: 'pointer' }}
                                            >My Statements</p>

                                        </div>
                                        <div className="col-6 col-xl-3 col-sm-6 pdrt masterLabel ">
                                            <p
                                             onClick={()=>{
                                                Navigate("/account-ledger")
                                             }}
                                             style={{ cursor: 'pointer' }}
                                            >Account Ledger</p>
                                        </div>
                                        <div className="col-6 col-xl-3 col-sm-6 pdrt masterLabel ">
                                            <p>Banking</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ margin: "8px 2px", borderRadius: '8px', border: '2px solid #d3d3d3' }}>
                                        <div className="row tp-form" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Total Balance</label>
                                                <h6>{(userMasterData.initialBalance)?.toFixed(2)}</h6>
                                            </div>
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Available Balance</label>
                                                <h6>{(userMasterData.currentBalance)?.toFixed(2)}</h6>
                                            </div>
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Net Exposer</label>
                                                <h6>{(userMasterData.exposer)?.toFixed(2)}</h6>
                                            </div>
                                            {/* <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Potential Gain</label>
                                                <h6>{(userMasterData.potentialGain)?.toFixed(2)}</h6>
                                                
                                            </div>
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Potential Loss</label>
                                                <h6>{(userMasterData.potentialLoss)?.toFixed(2)}</h6>
                                            </div> */}
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">PNL</label>
                                                <h6>{(userMasterData.pnl)?.toFixed(2)}</h6>
                                            </div>

                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Balance Down</label>
                                                <h6 className={userMasterData.transferPnl>0?"green":userMasterData.transferPnl<0?"red":""}>{(userMasterData.transferPnl)?.toFixed(2)}</h6>
                                            </div>
                                            <div className="col-6 col-xl-1 col-sm-6 pdrt">
                                                <label className="lbl">Balance Up</label>
                                                <h6 className={(userMasterData.fullpnl)>0?"red":(userMasterData.fullpnl)<0?"green":""}>{(-userMasterData.fullpnl)?.toFixed(2)}</h6>
                                            </div>
                                            {/* <div style={{display:"inline-block"}}>
                                                <label className="lbl">Risk</label>
                                                <div style={{display:'flex',flexDirection:"row"}}>
                                                <h6 style={{color:"red"}}>{(userMasterData.potentialLoss)?.toFixed(2)}</h6>
                                                <span>|</span>
                                                <h6 style={{color:"green"}}>{(userMasterData.potentialGain)?.toFixed(2)}</h6>
                                                </div>
                                            </div> */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {table == "player" && <PlayerTable currentPageChild={currentPageChild} page={currentPage} onUpdate={(incoming:any)=>updateParentData(incoming)}/>}
                            {table == "master" && <MasterTable currentPageChild={currentPageChild} page={currentPage} onUpdate={(incoming:any)=>updateParentData(incoming)}/>}
                            {table == "superMaster" && <SuperMasterTable page={currentPage} onUpdate={updateParentData}/>}
                            {table == "subAdmin" && <SubAdminTable page={currentPage} onUpdate={updateParentData}/>}
                            {table == "admin" && <AdminTable page={currentPage} onUpdate={updateParentData}/>}
                        </div>
                    </main>
                </div>
            )}
        </>
    );
}